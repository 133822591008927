<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center">
          <v-col cols="1">
            <v-btn color="warning" @click="$router.go(-1)">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="7" class="text-h5 font-weight-regular">
            {{tLabel("Dashboard convoglio")}}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-expansion-panels v-model="infoPanel" multiple>
              <v-expansion-panel>
                <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">{{tLabel("Info convoglio")}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <convoyInfoPanelFull :convoglio="convoglio" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-expansion-panels v-model="editPanel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">{{tLabel("Pianifica ingresso convoglio")}}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <convoyEditPanel :convoglio="convoglio" :editingEnabled="editingEnabled" showDate />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row v-if="convoglio.binario">
      <v-col cols="12">
        <div id="convoyVis" class="convoy-vis">
          <div id="convoyCntr" class="convoy-cntr">
            <convoySpatialVisualization :convoglio="convoglio" :lengthInterval="20" filterView="ALL" />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-btn
          x-large
          block
          title="Lavora scarichi convoglio"
          color="success"
          @click="$router.push({ path: `/callTreno/LavorazioneConvoglioArrivo/${convoyId}` })"
          :disabled="disabledLavorazione || readonly"
        >
          {{ tLabel('Lavora scarico') }}
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          x-large
          block
          title="Lavora carichi convoglio"
          color="primary"
          @click="$router.push({ path: `/callTreno/LavorazioneConvoglioPartenza/${convoyId}` })"
          :disabled="disabledLavorazione"
        >
          {{ tLabel('Lavora carico') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-expansion-panels v-model="wagonsPanel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">{{ tLabel("Gestione carri") }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <wagonOrdering :convoglio="convoglio" :editingEnabled="editingEnabled" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row v-if="editingEnabled">
      <v-col cols="12">
        <v-expansion-panels v-model="managementPanel" :popout="false" :inset="false" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">{{ tLabel("Gestione convoglio") }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <convoyActionsManagement :convoglio="convoglio" :listOccupazione="listOccupazioneBinari" :tabsRendered="[true, true, !convoyHideTabAssignVoyage]"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row v-if="cancelGateOutVisible">
      <v-col cols="3">
        <v-btn x-large @click="annullaGateOut" color="primary">
          {{tLabel("Annulla gate out").toUpperCase()}}
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn x-large @click="reIngressoConvoglio" color="primary">
          {{tLabel("Reingresso Treno").toUpperCase()}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import convoySpatialVisualization from "../../components/rails/convoy/ConvoySpatialVisualization";
import calLegend from "../../components/misc/Legend";
import wagonOrdering from "../../components/rails/convoy/WagonOrdering";
import convoyActionsManagement from "../../components/rails/convoy/ConvoyActionsManagement";
import convoyInfoPanelFull from "../../components/misc/ConvoyInfoPanelFull";
import convoyEditPanel from "../../components/misc/ConvoyEditPanel";
import convoyVisualizationMixins from "../../components/rails/mixins/convoyVisualizationMixins";
import railsMixins from "../../components/rails/mixins/railsMixins";

export default {
  name: "convoyVisualization",
  components: {
    convoySpatialVisualization,
    calLegend,
    wagonOrdering,
    convoyActionsManagement,
    convoyInfoPanelFull,
    convoyEditPanel,
  },
  props: {
    convoyId: {
      type: String,
      required: true,
    },
  },
  mixins: [convoyVisualizationMixins, railsMixins],
  data() {
    return {
      infoPanel: [0],
      editPanel: [1],
      legendPanel: [],
      wagonsPanel: [1],
      executionPanel: [0],
      managementPanel: [0],
      convoglio: {},
      listOccupazioneBinari: [],
      convoyHideTabAssignVoyage: (process.env.VUE_APP_CONVOY_HIDE_TAB_ASSIGN_VOYAGE === 'true'),
      readonly: false
    };
  },
  created() {
    this.$eventBus.$on("convoyReload", async () => {
      await this.$wsHubOnDataChangedEvent("railVisualizationRT");
      await this.fetchData();
    });
  },
  async mounted() {
    this.initComponent();
    await this.fetchData();
    const loggedUser = this.$store.getters['authentication/loggedUser'];
    this.readonly = loggedUser?.roles?.includes("EXT_TOS_USER");
  },
  computed: {
    statoConvoglioToLowerCase() {
      return this.convoglio && this.convoglio.stato ? this.convoglio.stato.toLowerCase() : null;  
    },
    editingEnabled() {
      return this.statoConvoglioToLowerCase != "uscitoterminal" && this.statoConvoglioToLowerCase != "inlavorazione";
    },
    cancelGateOutVisible() {
      return this.statoConvoglioToLowerCase === "uscitoterminal";
    },
    parkRailsEditingEnabled() {
      return this.cstatoConvoglioToLowerCase === "creato";
    },
    disabledLavorazione() {
      return !(
        (this.statoConvoglioToLowerCase === "interminal" || this.statoConvoglioToLowerCase === "inlavorazione" || this.statoConvoglioToLowerCase === "prontouscita")
      );
    },
  },
  watch: {
    convoyId: function(newId) {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      try {
        this.convoglio = await this.$api.trainData.getConvoglioById(+this.convoyId);
        const occBinResp = await this.$api.get(this.$apiConfiguration.BASE_PATH + "occupazioneBinari/list", {
          params: { filters: { convoglio_id___EQ: parseInt(this.convoyId) } },
        });
        this.listOccupazioneBinari = occBinResp.data;
      } catch (e) {
        console.log(e);
      }
    },
    async annullaGateOut() {
      try {
        await this.$api.trainData.annullaGateOutConvoglio(this.convoglio);
        this.showSuccess("Annullamento gate out effettuato correttamente");
      } catch (e) {
        console.log("Annulla gate out error:", e);
        this.showError(e.detailMessage || e);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },
    async reIngressoConvoglio() {
      try {
        await this.$api.trainData.reIngressoConvoglio(this.convoglio);
        this.showSuccess("Reingresso effettuato correttamente");
      } catch (e) {
        console.log("Reingresso error:", e);
        this.showError(e.detailMessage || e);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },

  },
};
</script>

<style scoped>
.convoy-vis {
  position: relative;
  overflow-x: scroll;
  cursor: pointer;
}
</style>
